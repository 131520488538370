<template>
  <div class="deramNightBox">
    <header>
      <span class="activeRules" @click="changeRulesFlag(1)">{{ $t("dreamRules") }}</span>
      <div class="sloganBox"></div>
      <p class="sloganText">{{ $t("dreamSend") }}</p>
      <p class="activeTime">UTC+3 8/30 19:00-9/8 24:00</p>
    </header>
    <div class="deramNightBody">
      <div class="actTipsBox">
        {{ $t("dreamRank") }}
      </div>
      <!-- 奖品预览 -->
      <div class="giftBox">
        <div class="listHeader">{{ $t("dreamPreview")  }}</div>
        <div class="listBox" :class="giftShowNum == 2?'hostBox':''">
          <div class="listTabBtn">
            <span :class="giftShowNum == 1 ? 'actTab' : ''" @click=changeGiftShowNum(1)>{{ $t("dreamSupporter")  }}</span>
            <span :class="giftShowNum == 2 ? 'actTab' : ''" @click=changeGiftShowNum(2)>{{ $t("dreamHost")  }}</span>
          </div>
          <div class="giftTabBtn">
            <div class="giftInfo " :class="giftReward == 1 ? 'actGiftInfo' : ''" @click="changeGiftReward(1)">
              <p>{{ $t("dreamRoseBasket")  }}</p>
              <img src="../../assets/deramNight/gift1.png" alt="">
            </div>
            <div class="giftInfo" :class="giftReward == 2 ? 'actGiftInfo' : ''" @click="changeGiftReward(2)">
              <p>{{ $t("dreamButterfly")  }}</p>
              <img src="../../assets/deramNight/gift2.png" alt="">
            </div>
            <div class="giftInfo" :class="giftReward == 3 ? 'actGiftInfo' : ''" @click="changeGiftReward(3)">
              <p>{{ $t("dreamLovers")  }}</p>
              <img src="../../assets/deramNight/gift3.png" alt="">
            </div>
            <div class="giftInfo" :class="giftReward == 4 ? 'actGiftInfo' : ''" @click="changeGiftReward(4)">
              <p>{{ $t("dreamFlower")  }}</p>
              <img src="../../assets/deramNight/gift4.png" alt="">
            </div>
          </div>
          <div class="giftSupporter" v-if="giftShowNum == 1">
            <ul class="rankGiftBox zuanshiTiao" v-if="giftReward == 1">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream7")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream5")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose") }}</p>
                  <p class="revserClass">{{ $t("dream3d") }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream3")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream3d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/zuanshi.png" alt="">
                  <p>{{ $t("dreamDiamonds") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
            </ul>
            <ul class="rankGiftBox zuanshiTiao" v-if="giftReward == 2">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream7")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream5")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose") }}</p>
                  <p>{{ $t("dream3d") }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream3")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/lvcheng.png" alt="">
                  <p>{{ $t("dreamHeart")  }}</p>
                  <p>{{ $t("dream3d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/zuanshi.png" alt="">
                  <p>{{ $t("dreamDiamonds") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
            </ul>
            <ul class="rankGiftBox" v-if="giftReward == 3">
              <li> 
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x{{ $t("dream15d")  }}</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream7")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x7d</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream5")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x3d</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream3")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream3d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/zuanshi.png" alt="">
                  <p>{{ $t("dreamDiamonds") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
            </ul>
            <ul class="rankGiftBox" v-if="giftReward == 4">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x{{ $t("dream15d")  }}</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream7")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x7d</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream5")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/xiamingpai.png" alt="">
                  <p>{{ $t("dreamVerification") }}</p>
                  <!-- <p>x3d</p> -->
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/shiji.png" alt="">
                  <p>{{ $t("dreamCentury")  }}</p>
                  <p>{{ $t("dream3")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream3d")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10") }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/zuanshi.png" alt="">
                  <p>{{ $t("dreamDiamonds") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="giftHost" v-if="giftShowNum == 2">
            <ul class="rankGiftBox" v-if="giftReward == 1">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream100000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream50000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream5d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream5000") }}</p>
                </div>
              </li>
            </ul> 
            <ul class="rankGiftBox" v-if="giftReward == 2">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream100000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream50000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/huangguan.png" alt="">
                  <p>{{ $t("dreamRose")  }}</p>
                  <p>{{ $t("dream5d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream25000") }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins") }}</p>
                  <p>{{ $t("dream5000") }}</p>
                </div>
              </li>
            </ul>
            <ul class="rankGiftBox" v-if="giftReward == 3">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream100000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream50000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream5d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream25000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream5000")  }}</p>
                </div>
              </li>
            </ul>
            <ul class="rankGiftBox" v-if="giftReward == 4">
              <li>
                <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream15d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream100000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream7d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream50000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                  <p>{{ $t("dreamUnicorn")  }}</p>
                  <p>{{ $t("dream5d")  }}</p>
                </div>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream25000")  }}</p>
                </div>
              </li>
              <li>
                <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                <div class="rankGiftImgInfo">
                  <img src="../../assets/deramNight/jinbi.png" alt="">
                  <p>{{ $t("dreamCoins")  }}</p>
                  <p>{{ $t("dream5000")  }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 排行榜 -->
      <div class="giftBox rankListBox">
        <div class="listHeader">{{ $t("dreamRankings") }}</div>
        <div class="listBox">
          <div class="listTabBtn">
            <span :class="rankListType == 1 ? 'actTab' : ''" @click='changeRankListType(1)'>{{ $t("dreamSupporter")  }}</span>
            <span :class="rankListType == 2 ? 'actTab' : ''" @click='changeRankListType(2)'>{{ $t("dreamHost")  }}</span>
          </div>
          <div class="giftTabBtn">
            <div class="giftInfo" :class="giftRewardRank == 1 ? 'actGiftInfo' : ''" @click="changeActivityId(1,'basketId')">
              <p>{{ $t("dreamRoseBasket")  }}</p>
              <img src="../../assets/deramNight/gift1.png" alt="">
            </div>
            <div class="giftInfo" :class="giftRewardRank == 2 ? 'actGiftInfo' : ''" @click="changeActivityId(2,'wonderlandId')">
              <p>{{ $t("dreamButterfly")  }}</p>
              <img src="../../assets/deramNight/gift2.png" alt="">
            </div>
            <div class="giftInfo" :class="giftRewardRank == 3 ? 'actGiftInfo' : ''" @click="changeActivityId(3,'everlastingId')">
              <p>{{ $t("dreamLovers")  }}</p>
              <img src="../../assets/deramNight/gift3.png" alt="">
            </div>
            <div class="giftInfo" :class="giftRewardRank == 4 ? 'actGiftInfo' : ''" @click="changeActivityId(4,'muchLoveId')">
              <p>{{ $t("dreamFlower")  }}</p>
              <img src="../../assets/deramNight/gift4.png" alt="">
            </div>
          </div>
          <!-- supporeter(送礼)榜单 -->
          <div v-if="rankListType == 1">
             <!-- 礼物榜单 -->
            <ul class="giftRankListInfo" v-if="anchorsTopObj.length">
              <li>
                <div class="top1_3Box">
                  <div class="top2Box" @click="skipUserInfo(anchorsTopObj[1])">
                    <div class="avatarFrame">
                      <img v-if="anchorsTopObj[1] && anchorsTopObj[1].headIcon" :src="anchorsTopObj[1].headIcon" alt="">
                      <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                      <span></span>
                    </div>
                    <div class="rankIcon"></div>
                    <p class="userName" v-if="anchorsTopObj[1]">{{ anchorsTopObj[1].nickname}}</p>
                    <div class="levelBox">
                      <span v-if="anchorsTopObj[1] && anchorsTopObj[1].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[1].wealthLevel)]">
                        <span>{{ anchorsTopObj[1].wealthLevel }}</span>
                      </span>
                      <span :class="['vip', 'vip' + anchorsTopObj[1].vipLevel] " v-if="anchorsTopObj[1] && anchorsTopObj[1].vipLevel > 0">{{ anchorsTopObj[1].vipLevel }}</span>
                    </div>
                    <p class="zuanshiBox" v-if="anchorsTopObj[1]">{{anchorsTopObj[1].score }}</p>
                  </div>
                  <div class="top2Box top1Box" @click="skipUserInfo(anchorsTopObj[0])">
                    <div class="avatarFrame">
                      <img  v-if="anchorsTopObj[0] && anchorsTopObj[0].headIcon" :src="anchorsTopObj[0].headIcon" alt="">
                      <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                      <span></span>
                    </div>
                    <div class="rankIcon"></div>
                    <p class="userName" v-if="anchorsTopObj[0]">{{ anchorsTopObj[0].nickname}}</p>
                    <div class="levelBox">
                      <span v-if="anchorsTopObj[0] && anchorsTopObj[0].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[0].wealthLevel)]">
                        <span>{{ anchorsTopObj[0].wealthLevel }}</span>
                      </span>
                      <span :class="['vip', 'vip' + anchorsTopObj[0].vipLevel]" v-if="anchorsTopObj[0] && anchorsTopObj[0].vipLevel > 0">{{ anchorsTopObj[0].vipLevel }}</span>
                    </div>
                    <p class="zuanshiBox" v-if="anchorsTopObj[0]">{{anchorsTopObj[0].score }}</p>
                  </div>
                  <div class="top2Box top3Box" @click="skipUserInfo(anchorsTopObj[2])">
                    <div class="avatarFrame">
                      <img  v-if="anchorsTopObj[2] && anchorsTopObj[2].headIcon" :src="anchorsTopObj[2].headIcon" alt="">
                      <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                      <span></span>
                    </div>
                    <div class="rankIcon"></div>
                    <p class="userName" v-if="anchorsTopObj[2]">{{ anchorsTopObj[2].nickname}}</p>
                    <div class="levelBox">
                      <span v-if="anchorsTopObj[2] && anchorsTopObj[2].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[2].wealthLevel)]">
                        <span>{{ anchorsTopObj[2].wealthLevel }}</span>
                      </span>
                      <span :class="['vip', 'vip' + anchorsTopObj[2].vipLevel]" v-if="anchorsTopObj[2] && anchorsTopObj[2].vipLevel > 0">{{ anchorsTopObj[2].vipLevel }}</span>
                    </div>
                    <p class="zuanshiBox" v-if="anchorsTopObj[2]">{{anchorsTopObj[2].score }}</p>
                  </div>
                </div>
                <div class="top3After">
                  <template v-for="(item,index) in anchorsTopObj">
                    <div class="top3AfterItem top4Item" :key="index" v-if="index > 2" @click="skipUserInfo(item)">
                      <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                      <p class="pictureBox">
                          <img  :src="item.headIcon" alt="">
                          <img class="top4toukuang" v-if="item.pictureFrameDetailMinUrl" :src="item.pictureFrameDetailMinUrl" alt="">
                          <!-- <span class="top4toukuang" v-if="item.pictureFrameDetailMinUrl" :style="`background:url(${item.pictureFrameDetailMinUrl}) no-repeat;background-size: 100% 100%;`"></span> -->
                      </p>
                      <div class="gifterRankingCenter">
                        <p class="userName">{{ item.nickname }}</p>
                        <p class="gradeIconBox">
                          <span v-if="item.wealthLevel > 0" :class="['wealth', countClass(item.wealthLevel)]">
                            <span>{{ item.wealthLevel }}</span>
                          </span>
                          <span :class="['vip', 'vip' + item.vipLevel]" v-if="item.vipLevel > 0">{{ item.vipLevel }}</span>
                        </p>
                      </div>
                      <p class="userMoney">{{item.score}}</p>
                    </div>
                  </template>
                </div>
              </li>
            </ul>
            <!-- 空状态 -->
            <div class="giftRankListInfo nullBox" v-else>
              <div></div>
            </div>
          </div>
          <!-- host（收礼）榜单 -->
          <div v-if="rankListType == 2" class="hostRankInfo">
              <!-- 礼物榜单 -->
              <ul class="giftRankListInfo" v-if="gifterTopObj.length > 0">
                <li>
                  <div class="top1_3Box">
                    <div class="top2Box" @click="skipUserInfo(gifterTopObj[1])">
                      <div class="avatarFrame">
                        <img  v-if="gifterTopObj[1] && gifterTopObj[1].headIcon" :src="gifterTopObj[1].headIcon" alt="">
                        <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                        <span></span>
                      </div>
                      <div class="rankIcon"></div>
                      <p class="userName" v-if="gifterTopObj[1]">{{ gifterTopObj[1].nickname}}</p>
                      <div class="levelBox">
                        <span v-if="gifterTopObj[1] && gifterTopObj[1].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[1].wealthLevel)]">
                          <span>{{ gifterTopObj[1].wealthLevel }}</span>
                        </span>
                        <span :class="['vip', 'vip' + gifterTopObj[1].vipLevel] " v-if="gifterTopObj[1] && gifterTopObj[1].vipLevel > 0">{{ gifterTopObj[1].vipLevel }}</span>
                      </div>
                      <p class="zuanshiBox" v-if="gifterTopObj[1]">{{gifterTopObj[1].score}}</p>
                    </div>
                    <div class="top2Box top1Box" @click="skipUserInfo(gifterTopObj[0])">
                      <div class="avatarFrame">
                        <img  v-if="gifterTopObj[0] && gifterTopObj[0].headIcon" :src="gifterTopObj[0].headIcon" alt="">
                        <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                        <span></span>
                      </div>
                      <div class="rankIcon"></div>
                      <p class="userName" v-if="gifterTopObj[0]">{{ gifterTopObj[0].nickname}}</p>
                      <div class="levelBox">
                        <span v-if="gifterTopObj[0] && gifterTopObj[0].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[0].wealthLevel)]">
                          <span>{{ gifterTopObj[0].wealthLevel }}</span>
                        </span>
                        <span :class="['vip', 'vip' + gifterTopObj[0].vipLevel]" v-if="gifterTopObj[0] && gifterTopObj[0].vipLevel > 0">{{ gifterTopObj[0].vipLevel }}</span>
                      </div>
                       <p class="zuanshiBox" v-if="gifterTopObj[0]">{{gifterTopObj[0].score }}</p>
                    </div>
                    <div class="top2Box top3Box" @click="skipUserInfo(gifterTopObj[2])">
                      <div class="avatarFrame">
                        <img  v-if="gifterTopObj[2] && gifterTopObj[2].headIcon" :src="gifterTopObj[2].headIcon" alt="">
                        <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                        <span></span>
                      </div>
                      <div class="rankIcon"></div>
                      <p class="userName" v-if="gifterTopObj[2]">{{ gifterTopObj[2].nickname}}</p>
                      <div class="levelBox">
                        <span v-if="gifterTopObj[2] && gifterTopObj[2].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[2].wealthLevel)]">
                          <span>{{ gifterTopObj[2].wealthLevel }}</span>
                        </span>
                        <span :class="['vip', 'vip' + gifterTopObj[2].vipLevel] " v-if="gifterTopObj[2] && gifterTopObj[2].vipLevel > 0">{{ gifterTopObj[2].vipLevel }}</span>
                      </div>
                      <p class="zuanshiBox" v-if="gifterTopObj[2]">{{gifterTopObj[2].score }}</p>
                    </div>
                  </div>
                  <div class="top3After">
                    <template v-for="(item,index) in gifterTopObj">
                      <div class="top3AfterItem top4Item" :key="index" v-if="index > 2" @click="skipUserInfo(item)">
                        <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                        <p class="pictureBox">
                           <img :src="item.headIcon" alt="">
                          <img class="top4toukuang" v-if="item.pictureFrameDetailMinUrl" :src="item.pictureFrameDetailMinUrl" alt="">
                            <!-- <span class="top4toukuang" v-if="item.pictureFrameDetailMinUrl" :style="`background:url(${item.pictureFrameDetailUrl}) no-repeat;background-size: 100% 100%;`"></span> -->
                        </p>
                        <div class="gifterRankingCenter">
                          <p class="userName">{{ item.nickname }}</p>
                          <p class="gradeIconBox">
                            <span v-if="item.wealthLevel > 0" :class="['wealth', countClass(item.wealthLevel)]">
                              <span>{{ item.wealthLevel }}</span>
                            </span>
                            <span :class="['vip', 'vip' + item.vipLevel]" v-if="item.vipLevel > 0">{{ item.vipLevel }}</span>
                          </p>
                        </div>
                        <p class="userMoney">{{item.score}}</p>
                      </div>
                    </template>
                    
                  </div>
                </li>
              </ul>
              <!-- 空状态 -->
              <div class="giftRankListInfo nullBox" v-else>
                <div></div>
              </div>
            </div>
        </div>
      </div>
      <!-- 自己名次信息 送礼-->
      <div class="selfRankInfoBox" v-if="rankListType == 1 && anchorsSelfInfo">
        <div class="top3AfterItem">
            <p class="rankingNum">{{ anchorsSelfInfo.top | topFilter}}</p>
            <p class="pictureBox">
              <img v-if="anchorsSelfInfo.headIcon" :src="anchorsSelfInfo.headIcon" alt="">
              <img v-else src="../../assets/deramNight/touNull.png" alt="">
            </p>
            <div class="gifterRankingCenter">
              <p class="userName">{{ anchorsSelfInfo.nickname }}</p>
              <p class="gradeIconBox">
                <span v-if="anchorsSelfInfo.wealthLevel > 0" :class="['wealth', countClass(anchorsSelfInfo.wealthLevel)]">
                  <span>{{ anchorsSelfInfo.wealthLevel }}</span>
                </span>
                <span :class="['vip', 'vip' + anchorsSelfInfo.vipLevel]" v-if="anchorsSelfInfo.vipLevel > 0"></span>
              </p>
            </div>
            <p class="userMoney">{{ anchorsSelfInfo.score}}</p>
          </div>
      </div>
      <!-- 自己名次信息 收礼-->
      <div class="selfRankInfoBox hostSelfRankInfo" v-if="rankListType == 2 && gifterSelfInfo">
        <div class="top3AfterItem">
            <p class="rankingNum">{{ gifterSelfInfo.top | topFilter}}</p>
            <p class="pictureBox">
              <img v-if="gifterSelfInfo.headIcon" :src="gifterSelfInfo.headIcon" alt="">
              <img v-else src="../../assets/deramNight/touNull.png" alt="">
            </p>
            <div class="gifterRankingCenter">
              <p class="userName">{{ gifterSelfInfo.nickname }}</p>
              <p class="gradeIconBox">
                <span v-if="gifterSelfInfo.wealthLevel > 0" :class="['wealth', countClass(gifterSelfInfo.wealthLevel)]">
                  <span>{{ gifterSelfInfo.wealthLevel }}</span>
                </span>
                <span :class="['vip', 'vip' + gifterSelfInfo.vipLevel]" v-if="gifterSelfInfo.vipLevel > 0"></span>
              </p>
            </div>
            <p class="userMoney">{{gifterSelfInfo.score }}</p>
          </div>
      </div>
    </div>
    <div class="rulesPopBox" v-if="rulesFlag">
      <span class="closeBtn" @click="changeRulesFlag(0)"></span>
      <div class="rulesBody">
        <div class="subRulesBody">
          <div class="rulesHeader">{{ $t("dreamTime")  }}</div>
          <div class="rulesContent">UTC+3 8/30 19:00-9/8 24:00</div>
          <div class="rulesHeader">{{ $t("dreamPlay")  }}</div>
          <div class="rulesContent">{{ $t("dreamUsersTop")  }}</div>
          <div class="rulesHeader">{{ $t("dreamPreview")  }}</div>
          <ul class="rulesGiftShow">
            <li>
              <div class="rulesGiftItem">
                <div class="rulesGiftImgBox">
                  <img src="../../assets/deramNight/gift1.png" alt="">
                  <img src="../../assets/deramNight/gift2.png" alt="">
                </div>
                <p>{{ $t("dreamSupporter")  }}</p>
                <p>{{ $t("dreamRewards")  }}</p>
              </div>
              <ul class="rankGiftBox">
                <li>
                  <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream15d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream7")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>{{ $t("dreamHeart")  }}</p>
                    <p>{{ $t("dream15d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream7d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream5")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>{{ $t("dreamHeart")  }}</p>
                    <p>{{ $t("dream7d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream3d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream3")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>{{ $t("dreamHeart")  }}</p>
                    <p>{{ $t("dream3d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/zuanshi.png" alt="">
                    <p>{{ $t("dreamDiamonds")  }}</p>
                    <p>{{ $t("dream25000")  }}</p>
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <div class="rulesGiftItem">
                <div class="rulesGiftImgBox">
                  <img src="../../assets/deramNight/gift3.png" alt="">
                  <img src="../../assets/deramNight/gift4.png" alt="">
                </div>
                <p>{{ $t("dreamSupporter")  }}</p>
                <p>{{ $t("dreamEverlasting")  }}</p>
              </div>
              <ul class="rankGiftBox">
                <li>
                  <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/xiamingpai.png" alt="">
                    
                    <p>{{ $t("dreamVerification")  }}</p>
                    <!-- <p>x15d</p> -->
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream7")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamFancy")  }}</p>
                    <p>{{ $t("dream15d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/xiamingpai.png" alt="">
                    <p>{{ $t("dreamVerification")  }}</p>
                    <!-- <p>x7d</p> -->
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream5")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamFancy")  }}</p>
                    <p>{{ $t("dream7d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/xiamingpai.png" alt="">
                    <p>{{ $t("dreamVerification")  }}</p>
                    <!-- <p>x3d</p> -->
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>{{ $t("dreamCentury")  }}</p>
                    <p>{{ $t("dream3")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamFancy")  }}</p>
                    <p>{{ $t("dream3d")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/zuanshi.png" alt="">
                    <p>{{ $t("dreamDiamonds")  }}</p>
                    <p>{{ $t("dream25000")  }}</p>
                  </div>
                </li>
              </ul>
            </li>
            <li class="addHostBottom">
              <div class="rulesGiftItem">
                <div class="rulesGiftImgBox">
                  <img src="../../assets/deramNight/gift3.png" alt="">
                  <img src="../../assets/deramNight/gift4.png" alt="">
                </div>
                <p>{{ $t("dreamHost")  }}</p>
                <p class="addHostBottomText">{{ $t("dreamHostBottom")  }}</p>
                <p>{{ $t("dreamRewards")  }}</p>
              </div>
              <ul class="rankGiftBox">
                <li>
                  <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream15d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream100000")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>心动旅程</p>
                    <p>x15d</p>
                  </div> -->
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream7d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream50000")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>心动旅程</p>
                    <p>x7d</p>
                  </div> -->
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/huangguan.png" alt="">
                    <p>{{ $t("dreamRose")  }}</p>
                    <p>{{ $t("dream5d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream25000")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/lvcheng.png" alt="">
                    <p>心动旅程</p>
                    <p>x3d</p>
                  </div> -->
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream5000")  }}</p>
                  </div>
                </li>
              </ul>
            </li>
            <li class="addHostBottom">
              <div class="rulesGiftItem">
                <div class="rulesGiftImgBox">
                  <img src="../../assets/deramNight/gift3.png" alt="">
                  <img src="../../assets/deramNight/gift4.png" alt="">
                </div>
                <p>{{ $t("dreamHost")  }}</p>
                <p class="addHostBottomText">{{ $t("dreamHostBottom")  }}</p>
                <p>{{ $t("dreamEverlasting")  }}</p>
              </div>
              <ul class="rankGiftBox">
                <li>
                  <p class="rankingText">{{ $t("dreamTop1")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamUnicorn")  }}</p>
                    <p>{{ $t("dream15d")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>辉煌世纪</p>
                    <p>x7</p>
                  </div> -->
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream100000")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop2")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamUnicorn")  }}</p>
                    <p>{{ $t("dream7d")  }}</p>
                  </div>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream50000")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/jinbi.png" alt="">
                    <p>金币</p>
                    <p>x5000</p>
                  </div> -->
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop3")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/dujiaoshou.png" alt="">
                    <p>{{ $t("dreamUnicorn")  }}</p>
                    <p>{{ $t("dream5d")  }}</p>
                  </div>
                  <!-- <div class="rankGiftImgInfo">
                    <img src="../../assets/deramNight/shiji.png" alt="">
                    <p>辉煌世纪</p>
                    <p>x3</p>
                  </div> -->
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream25000")  }}</p>
                  </div>
                </li>
                <li>
                  <p class="rankingText">{{ $t("dreamTop4_10")  }}</p>
                  <div class="rankGiftImgInfo">
                    <img class="jinbiImg" src="../../assets/deramNight/jinbi.png" alt="">
                    <p>{{ $t("dreamCoins")  }}</p>
                    <p>{{ $t("dream5000")  }}</p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "deramNight",
  data() {
    return {
      activityId:0,
      // 活动信息
      activityObj:{},
      rulesFlag:false,
      // 礼物展示
      giftShowNum:1,
      // 礼物榜单切换展示高亮
      giftRewardRank:1,
      // 礼物奖励
      giftReward: 1,
      // 榜单切换
      rankListType: 1,
      // 送礼榜单信息
      anchorsTopObj:[],
      // 收礼榜单信息
      gifterTopObj:[],
      // 送礼个人信息
      anchorsSelfInfo:null,
      // 个人榜收礼个人信息
      gifterSelfInfo:null,
      page:0,
      size:20,
      // 玫瑰花篮id
      basketId:0,
      // 蝴蝶仙境id
      wonderlandId:0,
      // 伊斯兰情侣id
      everlastingId:0,
      // 花id
      muchLoveId:0
    }
  },
  filters:{
    topFilter(top){
      if(top > 20){
        return '20+';
      }else if(top == 0){
        return '--'
      }else{
        return top > 9 ? top : '0' + top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
  },
  created() { 
    this.activityId = this.$route.query.basketId;
    this.basketId = this.$route.query.basketId;
    this.wonderlandId = this.$route.query.wonderlandId;
    this.muchLoveId = this.$route.query.muchLoveId;
    this.everlastingId = this.$route.query.everlastingId;
    //调用原生方法
    // this.$completes.complete("getUserInfo");
    // //原生调用h5方法
    // window.getUserInfo = this.getUserInfo;
    // window["getUserInfo"] = (data) => {
    //   this.getUserInfo(data);
    // };
    // 判断平台
    this.judgeClient()

    this.getUserInfo() 

  },
  methods: { 
    // 改变活动id
    changeActivityId(num,gift){
      this.giftRewardRank = num;
      this.activityId = this[gift];
      // 获取送礼榜单
      this.getSupporterRankingInfo()
      // 获取自己送礼信息
      this.getGifterSelfRankInfo();
    },
    // 规则展示
    changeRulesFlag(type){
      this.rulesFlag = type;
      if(type == 1){
        // 禁止页面滚动
        this.stopScroll();
      }else{
        this.canScroll()
      }
    },
    // 切换礼物展示
    changeGiftShowNum(num){
      this.giftShowNum = num;
    },
    // 切换礼物详情展示
    changeGiftReward(num){
      this.giftReward = num;
    },
    // 切换榜单展示
    changeRankListType(type){
      this.rankListType = type;
      this.giftRewardRank = 1;
      this.activityId = this.basketId;
      // 获取送礼榜单
      this.getSupporterRankingInfo()
      // 获取自己送礼信息
      this.getGifterSelfRankInfo();
    },
    // 获取自己送礼信息
    getGifterSelfRankInfo(){
      let rankInfo = {
        cfgId:this.activityId,
      };
      let paramObjs = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/modulerank/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          this.rankListType+
          "&cfgId=" +
          rankInfo.cfgId+
          "&type=" +
          this.rankListType,
        data: "",
        header: this.headers,
      };
      this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
        if (data.code == 200) {
          if(this.rankListType == 1){
            this.anchorsSelfInfo = data.data;
          }else{
            this.gifterSelfInfo = data.data;
          }
          
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.userId));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.userId));
      }
    },
    // 跳转房间
    toAppRoom(item){
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
      } else if (this.client == 'Android') {
        nativeJs["languageRoom"](JSON.stringify(item.roomId));
      }
    },
    getUserInfo(data) {
      // this.userInfo = JSON.parse(data);
      // this.token = JSON.parse(data).token
      // this.uid = JSON.parse(data).userId
      // let langs = this.userInfo.lang.substring(0,2)
      // let lang = this.userInfo.lang
      // this.headers = {
      //     fid:  this.userInfo.fid,
      //     os:  this.userInfo.os || 1,
      //     lang: this.userInfo.lang,
      //     version:this.userInfo.version,
      //     deviceid:this.userInfo.deviceId,
      //   }
      this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt5TWpneiIsInh5eiI6IlJUQTNORFZCTkRBdE1qUkdRaTAwT1RKQ0xVRXhNak10T1VFMFJEUkdNek14UVVJdyIsImV4cCI6MTk4NDAyNTYzMH0.KrZfZ2yE5MHJ28PRPqUrVETKmHnJSZeeV-PYJYcxIoU';
      this.uid = '792283'; 
      this.headers = {
          fid: '1001',
          os: 1,
          lang: 'zh-CN',
          version: '3.0',
          deviceid: 'E0745A40-24FB-492B-A123-9A4D4F331AB0',
      }
      let langs = 'en'
      let lang = 'zh_CN'
      if (langs == "ar") {
          this.$i18n.locale = "ar";
          document.title = "حلم ليلة منتصف الصيف"
      } else {
          this.$i18n.locale = "en";
          document.title = "Midsummer Night's Dream"
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      // 获取送礼榜单
      this.getSupporterRankingInfo()
      // 获取自己送礼信息
      this.getGifterSelfRankInfo();
      
    },
    // 获取个人送礼/收礼榜单
    getSupporterRankingInfo(){
        let that = this
        let paramObjs = {
          option: 'get',
          host: `${this.$serviceIpJava}api/activity/modulerank/role?token=${that.token}&uid=${that.uid}&cfgId=${this.activityId}&page=${this.page}&size=${this.size}&rankType=${this.rankListType}`,
          data: '',
          header: this.headers,
        };
        this.$axios.api(paramObjs.host,paramObjs.data,paramObjs.option,paramObjs.header).then((data) => {
          if (data.code == 200) {
            if(this.rankListType == 1){
              this.anchorsTopObj = data.data.pager.list
            }else{
              this.gifterTopObj = data.data.pager.list
            }
            
          }
          else {
            Toast(data.message || this.$t('failed'))
          }
        })
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },

    
  },
};
</script>

<style scoped>
@import './index.css';

@import '../../assets/gradeIcon/gradeIcon.css';
</style>
